import React from 'react'
import PropTypes from 'prop-types'

import MainHero from '../organisms/MainHero/MainHero'
import BlogRoll from '../BlogRoll'

export const BlogIndexPageTemplate = ({ title, image }) => {
  return (
    <>
      <MainHero title={title} image={image} hasGreyBackground />
      <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll />
          </div>
        </div>
      </section>
    </>
  )
}

BlogIndexPageTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    alt: PropTypes.string,
  }),
}

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Layout from '../../components/Layout'
import { BlogIndexPageTemplate } from '../../components/templates/blog-index-page'

export default () => (
  <StaticQuery
    query={graphql`
      query BlogIndexQuery {
        markdownRemark(
          frontmatter: { templateKey: { eq: "blog-index-page" } }
        ) {
          frontmatter {
            title
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 340, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    `}
    render={(data) => {
      const { frontmatter } = data.markdownRemark

      return (
        <Layout>
          <BlogIndexPageTemplate
            title={frontmatter.title}
            image={frontmatter.image}
          />
        </Layout>
      )
    }}
  />
)
